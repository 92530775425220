<template>
  <div class="app">
    <div class="hero">
      <div class="centered-content">
        <img class="logo" src="../assets/logo2.png" />
        <h2>Success Starts with a Solid Foundation</h2>
        <div class="button-group">
          <a href="https://www.youtube.com/channel/UCr_M8BEIpIYpGuR4DSH9_Hw" target="_blank"><button class="btn">Videos</button></a>
          <a href="https://foundationvr.spayee.com" target="_blank"><button class="btn">Login</button></a>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <h1 class="downloads-heading">VR Arcade Downloads</h1>
    <div class="downloads">
      <div class="download-section image-left-section">
        <a href="https://foundationvr.spayee.com/courses/All-Resources-62b5a5e60cf22b6d404a6b55" target="_blank"><img class="image" src="../assets/resources.jpg"/></a>
        <div>
          <h2><span style="text-decoration: line-through;">($99</span> $20) Companion Resources</h2>
          <p>
            Get instant access to over 30 checklists, sample policies,
            procedures and worksheets to eliminate the hassle of starting from
            scratch and ensuring that your VR Arcade is set up with a solid
            foundation from the start.
          </p>
          <a href="https://foundationvr.spayee.com/courses/All-Resources-62b5a5e60cf22b6d404a6b55" target="_blank"><button class="btn2">Purchase</button></a>
        </div>
      </div>
      <div class="divider"></div>
      <div class="download-section image-right-section">
        <div>
          <h2>(Free) Foundation Builder PDF</h2>
          <p>
            Download the 54 Common Mistakes that VR Arcade Owners Make PDF to
            avoid common pitfalls or our Recommendation Guide.
          </p>
          <a href="https://drive.google.com/file/d/1RGV4OWzFxFrs0-0RxHTpOEykDxk64S9L/view?usp=sharing" target="_blank"><button class="btn2">Free PDF</button></a>
          <a href="https://drive.google.com/file/d/1uzyclqmClcNqufit6HOR4aU1WxMux-Sj/view?usp=sharing" target="_blank"><button class="btn2" style="margin-left: 15px">Recommendation Guide</button></a>
        </div>
        <a href="https://drive.google.com/file/d/1uzyclqmClcNqufit6HOR4aU1WxMux-Sj/view?usp=sharing" target="_blank"><img class="image" src="../assets/builder.jpg" alt="Download" /></a>
      </div>
      <div class="divider"></div>
      <div class="download-section image-left-section">
        <a href="https://foundationvr.spayee.com/courses/14-Day-Bootcamp-62b623e50cf22f65941ca520" target="_blank"><img class="image" src="../assets/bootcamp.png" alt="Download" /></a>
        <div>
          <h2>14 Day BootCamp</h2>
          <p>
            We make you a personalized plan, guide you through the Beginner to
            Expert Course, critique your business and marketing plans and hold
            you accountable. 14 Day Money Back Guarantee, no questions asked.
          </p>
          <a href="https://foundationvr.spayee.com/courses/14-Day-Bootcamp-62b623e50cf22f65941ca520" target="_blank"><button class="btn2">Enroll Now</button></a>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <footer class="footer">
      <p>FoundationVR LLC 2023</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
.app {
  font-family: Arial, sans-serif;
  color: white;
  background-color: #f48162;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: white;
  color: #f48162;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.btn, .btn2 {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
}

.btn {
  background: #f48162;
  color: white;
}

.btn2 {
  background: white;
  color: #f48162;
}

.downloads-heading {
  text-align: center;
  color: white;
  padding: 20px 0;
}

.downloads {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.download-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1%;
  padding: 15px;
}

.image {
  width: 55vw;
  height: 80vh;
  object-fit: cover;
  border-radius: 55px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.logo {
  width: 55vw;
  height: 25vh;
}

.divider {
  background: url("../assets/divider.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 15vh;
  width: 100%;
  background-attachment: fixed;
}

/* New Media Query */
@media (max-width: 768px) {
  .hero,
  .downloads {
    flex-direction: column;
  }

  .download-section {
    flex-direction: column;
  }

  .image, .logo {
    width: 100%;
    height: auto;
  }
}
</style>
